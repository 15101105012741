import { BannedEmailResponse } from '@Api-generated';

export enum BannedEmailType {
  Manual = 'manual',
  Deleted = 'deleted',
}

export default class BannedEmailModel implements BannedEmailResponse {
  public id: string;
  public createdAt: string;
  public email: string;
  public type: BannedEmailType;

  constructor(id: string, createdAt: string, email: string, type: BannedEmailType) {
    this.id = id;
    this.createdAt = createdAt;
    this.email = email;
    this.type = type;
  }

  public static hydrateFromApi(response: BannedEmailResponse) {
    const type = response.type as BannedEmailType;

    return new BannedEmailModel(response.id, response.createdAt, response.email, type);
  }
}
