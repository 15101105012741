import UserModel from '@Api/models/UserModel';

import { UserReportResponse } from '@Api-generated';

export enum ReportReason {
  SuspiciousResult = 'suspiciousResult',
  LockedCheatedResult = 'lockedCheatedResult',
  AccurateUser = 'accurateUser',
}

export default class UserReportModel implements UserReportResponse {
  public id: string;
  public createdAt: string;
  public reporter: UserModel | null;
  public reportReason: ReportReason | null;
  public report: string | null;
  public hidden: boolean | null;

  constructor(
    id: string,
    createdAt: string,
    reporter: UserModel | null,
    reportReason: ReportReason | null,
    report: string | null,
    hidden: boolean | null
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.reporter = reporter;
    this.reportReason = reportReason;
    this.report = report;
    this.hidden = hidden;
  }

  public static hydrateFromApi(response: UserReportResponse): UserReportModel {
    const userModel = response.reporter ? UserModel.hydrateFromApi(response.reporter) : null;
    const reportReason = response.reportReason ? (response.reportReason as ReportReason) : null;

    return new UserReportModel(
      response.id,
      response.createdAt,
      userModel,
      reportReason,
      response.report,
      response.hidden
    );
  }
}
