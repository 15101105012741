import { ModBannedEmailListResponse } from '@Root/src/api-generated';

import BannedEmailModel from '@Api/models/BannedEmailModel';
import ModBannedEmailListMetaModel from '@Api/models/ModBannedEmailListMetaModel';

export default class ModBannedEmailListModel implements ModBannedEmailListResponse {
  public data: BannedEmailModel[];
  public meta: ModBannedEmailListMetaModel;

  constructor(data: BannedEmailModel[], meta: ModBannedEmailListMetaModel) {
    this.data = data;
    this.meta = meta;
  }

  public static hydrateFromApi(modUserListResponse: ModBannedEmailListResponse): ModBannedEmailListModel {
    const data = modUserListResponse.data?.map((modUserResponse) => BannedEmailModel.hydrateFromApi(modUserResponse));

    const meta = ModBannedEmailListMetaModel.hydrateFromApi(modUserListResponse.meta);

    return new ModBannedEmailListModel(data, meta);
  }
}
