import { ModBannedEmailListMetaResponse } from '@Root/src/api-generated';

// todo: replace with generated enum from backend as soon as native enums are supported in OpenApi
export enum ModBannedEmailListLimit {
  _20 = 20,
  _50 = 50,
  _100 = 100,
}

export enum ModBannedEmailListSortBy {
  Email = 'email',
  CreatedAt = 'createdAt',
  Type = 'type',
}

export default class ModBannedEmailListMetaModel implements ModBannedEmailListMetaResponse {
  public page: number;
  public limit: ModBannedEmailListLimit;
  public total: number;
  public totalPages: number;
  public sortBy: ModBannedEmailListSortBy;
  public sortDirection: string;
  public query: string | null;

  constructor(
    page: number,
    limit: ModBannedEmailListLimit,
    total: number,
    totalPages: number,
    sortBy: ModBannedEmailListSortBy,
    sortDirection: string,
    query: string | null
  ) {
    this.page = page;
    this.limit = limit;
    this.total = total;
    this.totalPages = totalPages;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.query = query;
  }

  public static hydrateFromApi(response: ModBannedEmailListMetaResponse): ModBannedEmailListMetaModel {
    const sortBy = ModBannedEmailListSortBy[response.sortBy as keyof typeof ModBannedEmailListSortBy];

    return new ModBannedEmailListMetaModel(
      response.page,
      response.limit,
      response.total,
      response.totalPages,
      sortBy,
      response.sortDirection,
      response.query
    );
  }
}
